/* @import url('https://db.onlinewebfonts.com/c/ae8f19f441b335c3fda30febf5069c5a?family=Gotham+Rounded+Light'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding-left: 0;
  height: 100%;
  overflow-x: hidden;
  background-color: #fff;
  user-select: none;
}

.home,
.admin,
.teachers,
.schools,
.attendance,
.sections,
.settings,
.resource,
.topics,
.contact,
.support,
.logout,
.sessions {
  width: 80%;
  margin-left: auto;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #454343;
  padding: 10px;
}
.session-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item {
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
  width: 100%;
}
.custom-list-item {
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 20px;
  line-height: 30px;
  background-color: #fff;
}

.containers {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
}
.activitydetails {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: 0;
  width: 100%;
  padding: 10px;
}
.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2px 8px 0 -48px;
}
.cards-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.justified-text {
  text-align: justify;
}

.title {
  font-size: 16px;
  width: fit-content;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 10px;
  background-color: #7dbfe9;
  padding: 5px 10px;
  color: #fff;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.pull-right {
  float: right !important;
}
.text-info {
  color: #337ab7;
}

input[type="text"],
input[type="file"],
input[type="email"],
input[type="password"],
select {
  width: 100%;
  padding: 5px;
  border: 1px solid #454343;
  border-radius: 5px;
  box-sizing: border-box;
  margin-top: 5px;
}

button[type="submit"]:hover {
  color: #fff;
  transition: all 0.7s ease;
}

/* Adding Session  */
.form-addactivitydetails {
  width: 100%;
  margin-top: 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.form-container {
  width: 100%;
  margin: 0;
  padding: 6px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.add_teachers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
/* Style for form labels */
.form-label {
  font-weight: bold;
}

/* Style for input fields */
.form-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* Style for submit button */
.form-submit {
  color: #454343;
  padding: 14px 20px;
  margin: 0;
  border: none;
  border-radius: 4px;
}

/* Style for submit button container */
.submit-container {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.submit_container {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 10px;
  gap: 5px;
}
.submit-content {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 10px;
}
.submit-content .back {
  padding: 10px 16px;
}
.table-container {
  width: 100%;
  margin-top: 0;
  padding: 6px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Footer section  */
.main-footer {
  bottom: 0;
  position: fixed;
  right: 0;
  width: 80%;
  margin-left: auto;
  background-color: #f8f8f8;
  color: #495057;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.main-footer strong {
  font-weight: bold;
}
.hide-footer {
  transform: translateY(100%);
  transition: transform 0.5s ease;
}
.form-container h2 {
  margin-top: 1rem;
}

/* Sessions  */
.sessions .activitylist {
  width: 100%;
  margin-top: 6px;
}
.form-submit textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
}
.upload {
  border: none;
  padding: 12px 16px;
  margin: 10px auto;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  background: #337ab7;
}
.update_activity {
  border: none;
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  color: #6c757d;
  background: #ef9e51;
}
.update_activity:hover {
  color: white;
}
.deleteactivity {
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  align-items: center;
  color: #6c757d;
  background: #f04545;
}
.deleteactivity:hover {
  color: #fff;
}
.confirmation-dialog {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 30%;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.confirmation-message {
  text-align: center;
  margin-bottom: 20px;
}
.confirmation-buttons {
  display: flex;
  justify-content: center;
  color: #000;
  gap: 20px;
}
.confirm-button,
.cancel-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.confirm-button {
  background-color: #007bff;
  color: #000;
}
.cancel-button {
  background-color: #e3e6ee;
  color: #000;
}
.confirm-button:hover {
  color: #fff;
}
.cancel-button:hover {
  color: #fff;
  background-color: #b3afaf;
}
.activity-container {
  margin: 10px auto;
}
.actions {
  margin-top: auto;
  display: flex;
  justify-content: center;
}
.texts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border-left: solid 12px #337ab7;
}
.text {
  width: 720px;
}
.no-content-message {
  width: 100%;
  padding: 20px;
  background-color: #ddd;
  border-radius: 4px;
  text-align: center;
}
.no-content-message p {
  margin: 0;
  font-size: 18px;
  color: #333;
  gap: 10px;
}
.card_header {
  background-color: #fff;
  border-left: solid 12px #337ab7;
  padding: 20px;
  gap: 5px;
  margin: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.card_header:hover {
  transform: scale(1.05);
  transition: all 0.7s ease;
  cursor: pointer;
}
.card-icon {
  margin: 2rem 2rem -6.5rem auto;
  padding: 20px;
  font-size: 80px;
  border-radius: 25%;
  color: #fff;
}
.card_header p {
  margin: 5px;
  gap: 0;
}
.card_header h2 {
  margin-top: 0;
}
.card_header a {
  margin-top: 10px;
  font-weight: bold;
  color: #337ab7;
  text-decoration: none;
}
.card_header a:hover {
  color: #ef9e51;
  transition: all 0.6s ease;
}
.card-icon.pri {
  background-color: #fcb900;
}
.card-icon.sec {
  background-color: #337ab7;
}
.content-header small {
  opacity: 0.7;
}
.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 3rem;
}
.breadcrumb {
  max-width: 1000px;
  float: right;
  margin-right: 5px;
  margin-bottom: 0;
  text-align: right;
}
.breadcrumb ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumb ol li {
  display: inline;
}
.breadcrumb a {
  text-decoration: none;
  color: #000;
}
.breadcrumb a:hover {
  color: #777;
}
.breadcrumb .breadcrumb-icon {
  vertical-align: top;
  margin-right: 5px;
}
.breadcrumb ol li:not(:last-child):after {
  content: "/";
  margin: 0 2px;
}
.breadcrumb ol li:last-child {
  top: 100%;
  left: 0;
  color: #777;
}

/* Display Page  */
.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.head {
  width: 100%;
  height: 3rem;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 20px;
  font-size: medium;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.head .icon {
  font-size: large;
  margin-right: 2px;
  padding-top: 5px;
}
.head a {
  text-decoration: none;
}
.edit a:hover,
.back a:hover {
  color: #fff;
}
.head a:hover {
  text-decoration: underline;
  color: #000;
}
h2 {
  margin-bottom: 20px;
}
.activity-head {
  margin-top: 1rem;
  width: 100%;
  height: 3.5rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 20px;
  font-size: medium;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
table {
  width: 100%;
  border-collapse: collapse;
}
table td {
  padding: 8px;
  border: 1px solid #ddd;
  font-size: large;
}

table th {
  font-size: large;
  padding: 8px;
  background: #337ab7;
  border: 1px solid #ddd;
  color: #fff;
  text-align: left;
}

table tr:hover {
  background-color: #ddd;
}
tr:nth-child(even) {
  background-color: #fff;
}

tr:nth-child(odd) {
  background-color: #f8f8f8;
}
.add-activity {
  border: none;
  padding: 8px 6px;
  color: #000;
  text-align: center;
  border-radius: 5px;
  background-color: #ddd;
}
.add-activity:hover {
  background-color: #7dbfe9;
  color: #fff;
}
.back {
  border: none;
  padding: 10px 24px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: #ef9e51;
}
.backward {
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background: #ef9e51;
}
.backward:hover {
  color: #fff;
}
.back:hover {
  color: #fff;
}
.delete {
  border: none;
  padding: 8px 16px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  background-color: rgb(241, 130, 130);
}
.delete:hover {
  color: #fff;
}

.edit {
  display: flex;
  width: 80px;
  justify-content: center;
  background-color: #ddd;
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}
a {
  text-decoration: none;
  color: #454343;
}
.session a:hover {
  color: #fff;
}

td .edit Link {
  text-decoration: none;
}
.session,
.add_session,
.add_activity {
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: center;
  background-color: #337ab7;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  gap: 5px;
}
.subtheme {
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  background-color: #337ab7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  gap: 5px;
}
.edit:hover {
  background: #ef9e51;
  text-decoration: none;
  color: #fff;
}
td .session:hover,
.add_session:hover,
.add_activity:hover,
.subtheme:hover {
  background: #0056b3;
  color: #fff;
}
table .action-column {
  display: flex;
  justify-content: center;
  align-items: center;
}
table .subtheme-action-column {
  display: flex;
  margin: 10px auto;
  justify-content: center;
  border: none;
}
table .action-columns {
  display: flex;
  justify-content: center;
}
.action {
  width: 200px;
}
table .operations {
  width: 140px;
  text-align: center;
  justify-content: space-between;
}

.teacherlist {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Add topics page  */
.topicslist {
  width: 100%;
  margin: 0;
  padding: 0;
}
.edittopics {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-top: 1.5rem;
}
.edittopic {
  border: none;
  padding: 10px 24px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  background-color: #337ab7;
}
.sessionadd {
  border: none;
  padding: 14px 24px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  background-color: #337ab7;
}
.link:hover {
  color: #fff;
}
h2 {
  margin-bottom: 20px;
}
label {
  display: block;
}

input,
select {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* SideBar */

.sidebar {
  width: 20%;
  height: 100%;
  background-color: #f8f8f8;
  color: #777;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}
.navbar-brand {
  position: fixed;
  width: 20%;
  margin: 0;
  height: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 16px;
  background-color: #337ab7;
}
.logo {
  height: 3rem;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
}
.navbar-brand h3 {
  margin: 5px auto;
  text-align: center;
  font-size: small;
  padding-left: 10px;
  cursor: pointer;
  color: #fff;
}
.sidebarWrapper {
  margin-top: 4rem;
  padding: 20px;
  border-radius: 5px;
}
.sidebarMenu {
  margin-bottom: 20px;
}
.sidebarTitle {
  color: #454343;
  font-size: 18px;
  margin-bottom: 10px;
}
.sidebarList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarListItem {
  display: flex;
  align-items: center;
  color: #777;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebarListItem.active {
  color: #337ab7;
  font-weight: bold;
}
.sidebarListItem:hover {
  color: #337ab7;
  border-radius: 5px;
  background-color: #ddd;
}
.sidebarIcon {
  margin-right: 6px;
}

/* NavBar codes */
.navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  margin-left: auto;
  background-color: #f8f8f8;
  color: #fff;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.menu {
  display: none;
  background-color: #337ab7;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
.menu:hover {
  background-color: #0056b3;
}
.nav-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  gap: 10px;
}
.nav-icons span {
  background: #337ab7;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
}
.notification-list {
  max-height: 300px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  width: 300px;
  padding: 10px;
}

.notification-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item h4 {
  margin: 0 0 5px;
  font-size: 16px;
  color: #333;
}

.notification-item p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.notification-item:hover {
  background-color: #f9f9f9;
}
.notification-empty {
  padding: 10px;
  text-align: center;
  color: #999;
}

.notification-dropdown {
  position: absolute;
  top: 4rem;
  right: 10px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 10px;
}

.profile-icon {
  text-decoration: none;
}
.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  padding: 5px;
  margin: 0;
}
.dropdown-toggle:hover {
  cursor: pointer;
}
.dropdown-toggle .caret-down {
  color: #337ab7;
}
.user-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

/* Editor Wrapper Styles */
.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin: 0;
}

.custom-editor .ql-editor {
  font-family: "Gotham Rounded Light", sans-serif;
  line-height: 1.2;
  margin: 0;
  padding: 5px;
}

.videofile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  text-decoration: none;
  gap: 5px;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #000;
  gap: 5px;
  margin-right: 1.2rem;
}
.progress {
  border: none;
  margin-left: 5px;
}
.tick-icon {
  font-size: 24px;
  color: green;
  margin-left: 10px;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 5px 20px;
  gap: 5px;
  cursor: pointer;
}

.panel {
  width: 48%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px;
}

.panel-heading {
  background-color: #337ab7;
  padding: 20px;
  color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.huge {
  font-size: 48px;
  margin-right: 10px;
}
.icon {
  margin-left: auto;
}
.custom-icon-right {
  color: #fff;
}
.panel-footer {
  padding: 10px 15px;
  border-top: 2px solid #fff;
  background-color: #f5f5f5;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #545353;
}
.panel-footer:hover {
  background: #ddd;
  color: #337ab7;
}
.dropdown {
  position: relative;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 0;
  min-width: 160px;
  z-index: 100;
}
.dropdown-item {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  color: #777;
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.dropdown-link {
  text-decoration: none;
  color: inherit;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #f8f8f8;
  cursor: pointer;
  color: #212529;
}
.dropdown-divider {
  border-top: 1px solid #ccc;
  margin: 4px 0;
}
.dropdown-header {
  color: #6c757d;
}
.fa-fw {
  background: #337ab7;
  color: #fff;
  font-size: larger;
  margin-bottom: -4px;
  border-radius: 50%;
  padding: 5px;
}
