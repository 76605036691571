
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


:root {
    --accent: #33b5e5;
    --white: #ffffff;
    --black: #000000;
    --dark-gray: #333333;
    --gray: #666666;
    --light-gray: #999999;
    --lighter-gray: #cccccc;
  }
  
  /* Body */
  body {
    background: #fff;
    color: var(--gray);
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Pen Title */
  .pen-title {
    padding: 50px 0;
    text-align: center;
    letter-spacing: 2px;
  }
  
  .pen-title h1 {
    margin: 0 0 20px;
    font-size: 48px;
    font-weight: 300;
  }
  
  .pen-title span {
    font-size: 12px;
  }
  
  .pen-title span .fa {
    color: var(--accent);
  }
  
  .pen-title span a {
    color: var(--accent);
    font-weight: 600;
    text-decoration: none;
  }
  
  /* Form Module */
  .modula {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../Assets/fbimage.png');
    background-size: cover; 
    background-position: center; 
    height: 100vh;
    position: relative; 
  }
  .modula::before {
    content: ""; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4); 
  }

  .form-module {
    position: relative;
    background: #f8f8f8;
    max-width: 320px;
    width: 100%;
    border-top: 5px solid #337ab7;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    margin: 5rem auto;
  }
  
  .form-module .toggle {
    cursor: pointer;
    position: absolute;
    top: -0;
    right: -0;
    background: #337ab7;
    width: 30px;
    height: 30px;
    margin: -5px 0 0;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .form-module .toggle .tooltip {
    position: absolute;
    top: 5px;
    right: -75px;
    display: block;
    background: #999999;
    border-radius: 4px;
    width: auto;
    padding: 10px;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;
  }
  
  .form-module .toggle .tooltip:before {
    content: '';
    position: absolute;
    top: 5px;
    left: -5px;
    display: block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid rgba(0, 0, 0, 0.6);
  }
  
  .form-module .form {
    display: none;
    padding: 20px 40px;
  }
  
  .form-module .form:nth-child(2) {
    display: block;
  }

  .form-module h1 {
    margin: 10px 0 30px;
    color: #545353;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    line-height: 1;
  }
  
  .form-module h2 {
    margin: 0 0 20px;
    color: #337ab7;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
  }
  
  .form-module input {
    outline: none;
    display: block;
    width: 100%;
    border: 1px solid #cccccc;
    margin: 0 0 20px;
    padding: 10px 15px;
    box-sizing: border-box;
    font-weight: 400;
    transition: .3s ease;
  }
  
  .form-module input:focus {
    border: 1px solid var(--accent);
    color: var(--dark-gray);
  }
  
  .form-module button {
    cursor: pointer;
    background: #337ab7;
    width: 100%;
    border: 0;
    padding: 10px 15px;
    color: #fff;
    border-radius: 4px;
    transition: .3s ease;
  }
  
  .form-module button:hover {
    background: #0056b3;
  }
  
  .form-module .cta {
    background: #e0e0e0;
    width: 100%;
    padding: 15px 40px;
    box-sizing: border-box;
    color: var(--gray);
    font-size: 12px;
    text-align: center;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .form-module .cta a {
    color: var(--dark-gray);
    text-decoration: none;
  }
  
  .error-message {
    font-size: medium;
    color: red;
    text-align: center;
    margin-top: 2.5rem;
  }